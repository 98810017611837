<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <el-select v-model="status" placeholder="状态" @change="onCurrentPage()">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入发货单号" suffix-icon="el-icon-search" v-model="deliveryNum" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商超名称" suffix-icon="el-icon-search" v-model="shopName" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商品名称" suffix-icon="el-icon-search" v-model="productName" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商品条形码" suffix-icon="el-icon-search" v-model="barCode" :clearable="true"
          @change="onCurrentPage()"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="deliveryNum" label="发货单号" show-overflow-tooltip min-width="140" align="center">
          </el-table-column>
          <el-table-column prop="city" label="地区" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="校区" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="purchaseCount" label="采购数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="deliveryCount" label="发货数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="receiveCount" label="收货数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="contactMan" label="商超联系人" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="contactTel" label="商超联系人电话" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="applicationStartTime" label="状态" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.warningStatus) }">
                {{ purchasingStatus[scope.row.warningStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="备注信息" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div style="cursor: pointer; color: rgb(6, 155, 188);" @click="getNoteInfoVisible(scope.row)">查看备注</div>
            </template>
          </el-table-column>
          <!-- 根据 row 对象中的字段来判断是否显示此列 -->
          <el-table-column label="操作" header-align="center" align="center" min-width="150" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="sendWritingRemarks(scope.row)">备注</el-button>
              <el-button type="primary" v-show="scope.row.warningStatus == 1" size="small"
                @click="handleCommand(scope.row)">确认</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
    <!-- 查看备注弹窗 -->
    <el-dialog title="备注信息" :visible.sync="noteInfoVisible" width="50%" center>
      <el-form label-width="0px" class="demo-ruleForm">
        <el-form-item>
          <el-input type="textarea" :value="remarkInfo" resize="none"
            :autosize="{ minRows: 10, maxRows: 10 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="noteInfoVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 添加备注信息 -->
    <el-dialog title="添加备注信息" :visible.sync="centerDialogVisible" width="50%" center>
      <el-form :model="dialogRuleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="备注信息" prop="remark">
          <el-input type="textarea" v-model="dialogRuleForm.remark" placeholder="请输入备注信息" resize="none"
            :autosize="{ minRows: 5, maxRows: 5 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <!-- <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      purchasingStatus: {
        1: '未处理',
        2: '已处理'
      },
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 状态
      selectData: [{
        value: '1',
        label: '未处理'
      }, {
        value: '2',
        label: '已处理'
      }
      ],
      status: '1',//状态
      deliveryNum: '',//发货单号
      shopName: '',//商超名称
      productName: '',//商品名称
      barCode: '',//商品条形码
      noteInfoVisible: false,//备注信息弹窗
      centerDialogVisible: false,//备注信息提交弹窗
      dialogRuleForm: {
        remark: '',//输入备注信息
        deliveryProductId: '',//id
      },
      rules: {
        remark: [
          { required: true, message: '请输入备注信息', trigger: 'blur' },
        ]
      },
      remarkInfo: '',//展示备注信息
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendDeliveryProductList, {
        params: {
          // warningStatus: this.status == '' ? 1 : this.status, //状态：1- 预警 2-已处理 
          warningStatus: Number(this.status), //状态：1- 预警 2-已处理 
          deliveryNum: this.deliveryNum || null,//发货单编号
          shopName: this.shopName || null,//商超名称
          productName: this.productName || null,//商品名称
          barCode: this.barCode || null,//商品名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 异常处理
    getRequestExceptions(deliveryProductId) {
      this.$axios.post(this.$api.sendExceptions, deliveryProductId).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({
            message: '恭喜你，处理成功！',
            type: 'success'
          });
          this.onCurrentPage()
          location.reload()
        }
      }).catch((err) => {
        console.log(err);
      })

    },
    // 点击通过
    handleCommand(row) {
      // console.log(row);
      this.$confirm('确认通过当前异常信息审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'

      }).then(() => {
        this.getRequestExceptions(row.deliveryProductId)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      })
    },
    // 状态文字颜色
    getStatusColor(status) {
      if (status == 1) {
        return '#FF0000';
      } else {
        return '';
      }
    },
    // 填写备注
    sendWritingRemarks(row) {
      this.dialogRuleForm.deliveryProductId = row.deliveryProductId
      this.centerDialogVisible = true
    },
    // 提交备注
    submitForm(formName) {
      const { dialogRuleForm } = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.addRemarks, dialogRuleForm).then((res) => {
            const { code } = res.data
            if (code == 100) {
              this.$message({
                message: '恭喜你，备注成功！',
                type: 'success'
              });
              this.onCurrentPage()
              this.resetForm()
            }
          }).catch((err) => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置校验
    resetForm() {
      this.dialogRuleForm = {}
      this.centerDialogVisible = false
      this.$refs.ruleForm.resetFields();
    },
    // 查看备注
    getNoteInfoVisible(row) {
      this.remarkInfo = ''
      if (row.remark) {
        this.noteInfoVisible = true
        // console.log(row.remark);
        this.remarkInfo = row.remark
      } else {
        this.$message.error('暂无备注信息！');
      }
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      //}
    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
